import { createApp } from "vue";
import App from "./App.vue";
import Vant from "../plugins/Vant";
import router from "./router";
import "./assets/rem.ts";
import "./assets/reset.css";
require("@/provide/wxConfig");

const app = createApp(App);
app.use(Vant);
app.use(router).mount("#app");
