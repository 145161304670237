import request from "@/provide/fetch";
type PageParams = {
  pageIndex: number;
  pageSize: number;
};

// 获取产品列表
export async function requestProductsList({
  pageIndex,
  pageSize,
}: PageParams): Promise<any> {
  return request({
    url: "/api/sku/page",
    method: "GET",
    params: {
      page_index: pageIndex,
      page_size: pageSize,
    },
  });
}

// 搜索医院
export async function requestHospitalsList({
  pageIndex,
  pageSize,
  hospitalName,
  salesman_id = 0,
}: PageParams & {
  hospitalName: string;
  salesman_id?: number | string;
}): Promise<any> {
  return request({
    url: "/api/hospital/search",
    method: "GET",
    params: {
      hospital_name: hospitalName,
      page_index: pageIndex,
      page_size: pageSize,
      salesman_id,
    },
  });
}
// 搜索医院的科室和医生
export async function requestDoctorList(
  hospitalId: number | string
): Promise<any> {
  return request({
    url: "/api/hospital/depart-doctor-list",
    method: "GET",
    params: {
      hospital_id: hospitalId,
    },
  });
}
// 创建订单
export async function placeOrder({
  cardName,
  certificateType,
  cardNo,
  hospitalId,
  // departId,
  // doctorId,s
  skuId,
  salesmanId,
}: any): Promise<any> {
  return request({
    url: "/api/store-order/create",
    method: "POST",
    data: {
      cardName,
      certificateType,
      cardNo,
      hospitalId,
      // departId,
      // doctorId,
      skuId,
      salesmanId,
    },
  });
}
// 支付订单
export async function payOrder({
  openId,
  orderNo,
}: {
  openId: string;
  orderNo: string;
}): Promise<any> {
  return request({
    url: "/api/store-order/pay",
    method: "POST",
    data: {
      openId,
      orderNo,
    },
  });
}
// 查询支付结果
export async function checkPaymentResult(orderNo: string): Promise<any> {
  return request({
    url: `/api/store-order/query/${orderNo}`,
    method: "POST",
  });
}

// 订单信息绑定
export async function bindingInformation(data: any): Promise<any> {
  return request({
    url: "/api/sample-binding/binding",
    method: "POST",
    data,
  });
}

// 获取七牛云token
export async function requestQNToken(): Promise<any> {
  return request({
    url: "/api/qiniu/token",
  });
}

// 根据订单号查询受检人信息
export function requestInfoByorderNo(orderNo: string) {
  return request({
    url: `/api/test-user/${orderNo}`,
  });
}

// 查询用户信息
export function requestUserInfo() {
  return request({
    url: `/api/user/info`,
  });
}
// 绑定样本编号验证
export function validSampleBindingOrder(sampleCode: string, orderNo: string) {
  return request({
    url: `/api/sample-binding/valid/${sampleCode}/${orderNo}`,
  });
}
// 公众号-支付下单后签名
export function signName(orderNo: any, userSign: string) {
  return request({
    url: `/api/sample-binding/sign-name`,
    method: "POST",
    data: {
      userSign,
      orderNo,
    },
  });
}

// 退出登录
export function logout() {
  return request({
    url: "/api/user/logout",
  });
}

// 用户获取订单列表
export function requestOrderList(pageIndex: number, pageSize = 10) {
  return request({
    url: "/api/store-order/listUserOrder",
    method: "POST",
    data: {
      pageIndex,
      pageSize,
      data: localStorage.getItem("userId"),
    },
  });
}
// 根据用户获取订单详情
export function requestOrderDetails(orderId: string) {
  return request({
    url: `/api/store-order/detail/${orderId}`,
    method: "GET",
  });
}

// 申请发票
export function requestInvoice(orderId: string) {
  return request({
    url: `/api/store-order/invoice/${orderId}`,
  });
}

// 申请纸质报告
export function requsetPaper(data: any) {
  return request({
    url: `/api/store-order/paper`,
    method: "POST",
    data,
  });
}

// ------------------------------ 业务员端 ---------------------------
// 获取用户对应医院
export function requestHospitalListByUser() {
  return request({
    url: "/api/hospital/getHospitalMenu",
    params: {
      userId: localStorage.getItem("userId"),
    },
  });
}
// 查询样本对应关系
export function checkSample(sampleCode: string) {
  return request({
    url: "/api/mp-store-sample/checkSampleBelongs",
    method: "GET",
    params: {
      salesmanId: localStorage.getItem("userId"),
      sampleCode,
    },
  });
}
// 样本出库
export function sampleOutStock(data: any) {
  return request({
    url: "/api/mp-store-sample/outStock",
    method: "POST",
    data,
  });
}
// 样本回库
export function refundSample(sampleCode: string) {
  return request({
    url: "/api/mp-store-sample/refundStock",
    method: "POST",
    data: {
      sampleCode,
    },
  });
}
// 样本列表
export function querySampleList({
  pageIndex,
  pageSize,
  sampleStatus,
}: PageParams & { sampleStatus?: number }) {
  return request({
    url: "/api/mp-store-sample/listSample",
    method: "POST",
    data: {
      pageIndex,
      pageSize,
      data: {
        salesmanId: Number(localStorage.getItem("userId")),
        sampleStatus,
      },
    },
  });
}
// 业务员订单列表
export function requerySalesmanOrderList(pageIndex: number, pageSize = 10) {
  return request({
    url: "/api/store-order/listOrder",
    method: "POST",
    data: {
      data: Number(localStorage.getItem("userId")),
      pageIndex,
      pageSize,
    },
  });
}
// 订单信息绑定
export async function bindingInformationBySalesman(data: any): Promise<any> {
  return request({
    url: "/api/sample-binding/saveBinding",
    method: "POST",
    data,
  });
}
// 呼叫快递
export async function sendingExpress(data: any): Promise<any> {
  return request({
    url: "/api/mp-store-order-express/callCourier",
    method: "POST",
    data,
  });
}
// 获取快递信息
export async function queryExpressInfo(orderId: string) {
  return request({
    url: "/api/mp-store-order-express/getExpress",
    params: {
      orderId,
    },
  });
}
// 跳过快递
export async function skipExpress(data: any): Promise<any> {
  return request({
    url: "/api/mp-store-order-express/skip-express",
    method: "POST",
    data,
  });
}
// ---------------------------------------- 医生端 ------------------------------------
export function getDoctorsAccount() {
  return request({
    url: "/api/user/review-doctors",
  });
}
export function queryDoctorReportsList(data: any) {
  return request({
    url: "/api/mp-store-test-report/listTestReport",
    method: "POST",
    data,
  });
}
// 查询报告
export function queryReport(reportId: string) {
  return request({
    url: "/api/mp-store-test-report/getTestReport",
    params: {
      reportId,
    },
  });
}
// 审核报告：同意/拒绝
export function cheekReport(data: {
  reportStatus: 2 | 3; // 2-通过 3-拒绝
  reportId: string;
  lastRejectReason?: string;
}) {
  return request({
    url: "/api/mp-store-test-report/checkReport",
    method: "POST",
    data: {
      reportStatus: data.reportStatus,
      reportId: data.reportId,
      lastRejectReason: data.lastRejectReason,
    },
  });
}

// ------- 文章相关 -------
export function requestArticalList(
  data: PageParams & { data: number | string }
) {
  return request({
    url: "/api/mp/article/page",
    method: "POST",
    data,
  });
}
export function requestArticalDetail(articleId: number) {
  return request({
    url: "/api/mp/article/getById",
    params: {
      articleId,
    },
  });
}

//  公众号--获取医院的业务员
export function hospitalSalesman(hospitalId: number) {
  return request({
    url: "/api/hospital/salesman",
    method: "GET",
    params: {
      hospital_id: hospitalId,
    },
  });
}
// 公众号-业务员设置订单的科室和医生
export function departDoctor(orderId: any, departId: any, doctorId: any) {
  return request({
    url: "/api/store-order/depart-doctor",
    method: "GET",
    params: {
      "order-id": orderId,
      "depart-id": departId,
      "doctor-id": doctorId,
    },
  });
}
