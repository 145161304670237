import request from "./fetch";

getConfigData();
async function getConfigData() {
  const res = await request({
    url: "/api/auth/get-wx-config",
    method: "POST",
    data: {
      url: location.href,
    },
  });
  if (res.code === 200) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: res.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.signature, // 必填，签名
      jsApiList: ["scanQRCode", "chooseWXPay"], // 必填，需要使用的 JS 接口列表
    });
  }
}
