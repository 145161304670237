import type { App } from "vue";
import {
  Button,
  Field,
  CellGroup,
  Icon,
  PasswordInput,
  NumberKeyboard,
  NavBar,
  List,
  Tabbar,
  TabbarItem,
  Form,
  Popup,
  Cascader,
  Picker,
  Checkbox,
  SubmitBar,
  Search,
  RadioGroup,
  Radio,
  Image,
  Overlay,
  Loading,
  DatePicker,
  Tag,
  Tabs,
  Tab,
  Calendar,
} from "vant";

const vant = {
  install(Vue: App): void {
    Vue.use(Button);
    Vue.use(Field);
    Vue.use(CellGroup);
    Vue.use(Icon);
    Vue.use(PasswordInput);
    Vue.use(NumberKeyboard);
    Vue.use(NavBar);
    Vue.use(List);
    Vue.use(Tabbar);
    Vue.use(TabbarItem);
    Vue.use(Form);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Cascader);
    Vue.use(Checkbox);
    Vue.use(SubmitBar);
    Vue.use(Search);
    Vue.use(RadioGroup);
    Vue.use(Radio);
    Vue.use(Image);
    Vue.use(Overlay);
    Vue.use(Loading);
    Vue.use(DatePicker);
    Vue.use(Tag);
    Vue.use(Tabs);
    Vue.use(Tab);
    Vue.use(Calendar);
  },
};

export default vant;
