import Axios, { Method } from "axios";
import { Dialog } from "vant";

const baseURL = location.origin;
interface Options {
  url: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  mode?: "no-cors" | "cors" | "same-origin";
  cache?: "no-cache" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "include" | "same-origin" | "omit";
  headers?: any;
  data?: any;
  params?: any;
}
export async function request(option: Options) {
  const defaultOption: any = {
    method: option?.method || "GET", // *GET, POST, PUT, DELETE, etc.
    // mode: option?.mode || "no-cors", // no-cors, *cors, same-origin
    mode: option?.mode || "cors", // no-cors, *cors, same-origin
    "Access-Control-Allow-Origin": "*",
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    // "Access-Control-Allow-Credentials": true,
    // cache: option?.cache || 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'include', // include, *same-origin, omit
    // headers: {
    //   'Content-Type': 'application/json',
    //   // 'Content-Type': 'application/x-www-form-urlencoded',
    // },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  if (defaultOption.method !== "GET") {
    if (option.data) defaultOption.body = JSON.stringify(option.data);
  } else {
    if (option.params) {
      const paramsArray: any[] = [];
      //拼接参数
      Object.keys(option.params).forEach((key) =>
        paramsArray.push(key + "=" + option.params[key])
      );
      if (option.url.search(/\?/) === -1) {
        option.url += "?" + paramsArray.join("&");
      } else {
        option.url += "&" + paramsArray.join("&");
      }
    }
  }
  defaultOption.headers = { ...defaultOption.headers, ...option.headers };
  const response = await fetch(
    option.url.startsWith("http") ? option.url : baseURL + option.url,
    {
      ...defaultOption,
    }
  );
  const res: any = response.json(); // parses JSON response into native JavaScript objects
  return res;
}

export default async function (option: Options) {
  if (window.fetch && typeof window.fetch === "function") {
    return request(option).then((res) => {
      if (res.code === 401) {
        return Dialog.alert({ title: "提示", message: res.message }).then(
          () => {
            localStorage.clear();
            location.replace(
              `/login?userType=${localStorage.getItem("userType") || 1}`
            );
          }
        );
      }
      return res;
    });
  } else {
    const axios = Axios.create({
      baseURL,
      timeout: 20000, // 请求超时 20s
    });
    return axios(option).then((res: any) => {
      if (res.code === 401) {
        return Dialog.alert({ title: "提示", message: res.message }).then(
          () => {
            location.replace("/login");
          }
        );
      }
      return res;
    });
  }
}
