import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/index.vue";
import Login from "@/views/login.vue";
import VerificationCode from "@/views/VerificationCode.vue";
import Products from "@/views/Products.vue";
import ProductDetails from "@/views/ProductDetails.vue";
import PaymentSuccess from "@/views/PaymentSuccess.vue";
import InformationBinding from "@/views/InformationBinding.vue";
import BindingSuccess from "@/views/BindingSuccess.vue";
import PersonalCenter from "@/views/PersonalCenter.vue";
import MyOrders from "@/views/MyOrders.vue";
import OrderDetails from "@/views/OrderDetails.vue";
import ReviewReport from "@/views/ReviewReport.vue";
import SalesmanPersonalCenter from "@/views/salesman/PersonalCenter.vue";
import SalesmanScanOut from "@/views/salesman/ScanOut.vue";
import SalesmanMyOrders from "@/views/salesman/MyOrders.vue";
import SalesmanOrderDeatils from "@/views/salesman/OrderDetails.vue";
import SalesmanSendingExpress from "@/views/salesman/SendingExpress.vue";
import SalesmanSendingExpressSuccess from "@/views/salesman/SendingExpressSuccess.vue";
import SalesmanSampleList from "@/views/salesman/SampleList.vue";
import SalesmanSampleDetails from "@/views/salesman/SampleDetails.vue";
import SalesmanInformationBinding from "@/views/salesman/InformationBinding.vue";
import SalesmanBindingSuccess from "@/views/salesman/BindingSuccess.vue";
import DoctorReportList from "@/views/doctor/List.vue";
import DoctorReportDetail from "@/views/doctor/orderDetail.vue";
import InformedConsent from "@/components/informedConsent.vue";
import ArticalList from "@/views/ArticalList.vue";
import SalesmanDepartDoctor from "@/views/salesman/DepartDoctor.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/verification-code",
    name: "verificationCode",
    component: VerificationCode,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/product-details",
    name: "ProductDetails",
    component: ProductDetails,
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: PaymentSuccess,
  },
  {
    path: "/information-binding",
    name: "InformationBinding",
    component: InformationBinding,
  },
  {
    path: "/binding-success",
    name: "BindingSuccess",
    component: BindingSuccess,
  },
  {
    path: "/personal-center",
    name: "PersonalCenter",
    component: PersonalCenter,
  },
  {
    path: "/my-orders",
    name: "MyOrders",
    component: MyOrders,
  },
  {
    path: "/order-details",
    name: "OrderDetails",
    component: OrderDetails,
  },
  {
    path: "/review-report",
    name: "ReviewReport",
    component: ReviewReport,
  },
  {
    path: "/informed-consent",
    name: "InformedConsent",
    component: InformedConsent,
  },
  {
    path: "/salesman-personal-center",
    name: "SalesmanPersonalCenter",
    component: SalesmanPersonalCenter,
  },
  {
    path: "/salesman-scan-out",
    name: "SalesmanScanOut",
    component: SalesmanScanOut,
  },
  {
    path: "/salesman-my-orders",
    name: "SalesmanMyOrders",
    component: SalesmanMyOrders,
  },
  {
    path: "/salesman-order-details",
    name: "SalesmanOrderDetails",
    component: SalesmanOrderDeatils,
  },
  {
    path: "/salesman-sending-express",
    name: "SendingExpress",
    component: SalesmanSendingExpress,
  },
  {
    path: "/salesman-sending-express-success",
    name: "SalesmanSendingExpressSuccess",
    component: SalesmanSendingExpressSuccess,
  },
  {
    path: "/salesman-sample-list",
    name: "SalesmanSampleList",
    component: SalesmanSampleList,
  },
  {
    path: "/salesman-sample-details",
    name: "SalesmanSampleDetails",
    component: SalesmanSampleDetails,
  },
  {
    path: "/salesman-information-binding",
    name: "SalesmanInformationBinding",
    component: SalesmanInformationBinding,
  },
  {
    path: "/salesman-binding-success",
    name: "SalesmanBindingSuccess",
    component: SalesmanBindingSuccess,
  },
  {
    path: "/salesman-departDoctor",
    name: "SalesmanDepartDoctor",
    component: SalesmanDepartDoctor,
  },
  // 医生端
  {
    path: "/doctor-list",
    name: "DoctorReportList",
    component: DoctorReportList,
  },
  {
    path: "/doctor-report-detail",
    name: "DoctorReportDetail",
    component: DoctorReportDetail,
  },
  // 文章列表
  {
    path: "/artical-list",
    name: "ArticalList",
    component: ArticalList,
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
